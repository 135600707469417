module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"XLTools Add-in for Excel","short_name":"XLTools Add-in","start_url":"https://xltools.net/","background_color":"#FFFFFF","theme_color":"#01B671","description":"Simplify your daily Excel workload: clean data, track changes, automate tasks, and more.","categories":["Excel","Office apps","productivity","business"],"display":"minimal-ui","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1122035d4b781f2e3646afd916574b87"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TW8X3Q","defaultDataLayer":{"type":"function","value":"() => ({\n          pageType: window.pageType,\n        })"},"includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
