import LanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";
import en from "./assets/translations/en.json";
import ru from "./assets/translations/ru.json";

// import Backend from "i18next-xhr-backend";

i18next
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    resources: {
      ru,
      en,
    },
    fallbackLng: ["en", "ru"],
    debug: false,
    whitelist: ["en", "ru"],
    react: {
      // wait: false,
      useSuspense: true,
    },
  });

export default i18next;
